<template>
	<ion-page id="uber">
		<PageHeader :title="'Über'"></PageHeader>

		<ion-content class="inset-container">
			<ion-list>

				<ion-item v-for="item in ubers" :key="item.id" :detail="true" :router-link="`/uber/${item.id}`">
					<ion-label>
						<h2>{{ item.title }}</h2>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonPage,
	IonList,
	IonItem,
	IonLabel,

} from '@ionic/vue';

import PageHeader from '@/components/PageHeader.vue';

export default {
	name: 'WaWi',
	components: {
		IonContent,
		IonPage,
		PageHeader,
		IonList,
		IonItem,
		IonLabel,
	},
	data() {
		return {
			ubers: {},
		};
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				'/items/uber?fields=*&sort=title'
			);
			this.ubers = data.data;
		},
	},
};
</script>

<style lang="scss" scoped>
#uber {

	ion-content, ion-list {
		--background: white;
	}

	ion-list:last-child {
		padding-bottom: 100px;
	}

	ion-item {
		cursor: pointer;
	}


}
</style>
